export default {
    code: [
      {
        required: true,
        message: 'Kode Tidak Boleh Kosong',
      },
      {
        max: 9,
        min: 0,
        message: 'Maximal kode 9 angka',
      },
    ],
    owner: [
      {
        required: true,
        message: 'Nama pemilik tidak boleh kosong!',
      },
    ],
    name: [{ required: true, message: 'Nama distributor tidak boleh kosong!' }],
  
    // vendorCodes: {
    //   code: [{ required: true, message: 'Kode tidak boleh kosong!' }],
    // },
    email: [
      {
        required: true,
        message: 'Email tidak boleh kosong!',
      },
      {
        type: 'email',
        message: 'Format email salah',
      },
    ],
    phone: [
      {
        required: true,
        message: 'Nomor Telepon tidak boleh kosong!',
      },
      {
        max: 12,
        min: 0,
        message: 'Maximal nomor telepon 12 angka',
      },
      {
        pattern: /^[0-9]*$/,
        message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
      },
    ],
    npwp: [
      {
        required: true,
        message: 'NPWP tidak boleh kosong!',
      },
      {
        pattern: /^[0-9\.\-]*$/,
        message: `NPWP tidak boleh memuat karakter selain angka,'.',dan '-'`,
      },
    ],
    wilayah_id: [
      {
        required: true,
        message: 'Kabupaten/Kota tidak boleh kosong!',
      },
    ],
    address: [
      {
        required: true,
        message: 'Alamat tidak boleh kosong!',
      },
    ],
    vendors: [
      {
        required: true,
        message: 'distributor tidak boleh kosong!',
      },
    ],
    lat:[
      {
        required: true,
        message: 'latitude tidak boleh kosong',
      },
    ],
    lng: [
      {
        required: true,
        message: 'longitude tidak boleh kosong',
      },
    ],
    cluster_id: [
      {
        required: true,
        message: 'cluster tidak boleh kosong!',
      },
    ],
  }
  