<template>
    <div>
      <a-alert
        v-if="errorMessage"
        type="error"
        :message="errorMessage"
        banner
        closable
        @close="errorMessage = null"
      />
      <div class="row">
        <div class="col-md-12">
          <filter-regional class="m-2" v-model:value="region" v-can:hide.distributor/>
          <filter-province
            class="m-2 mt-2"
            v-model:value="provinsi"
            v-model:region="region"
            v-can:hide.distributor
          />
          <filter-area
            class="m-2 mt-2"
            v-model:value="area"
            v-model:area="area"
            v-model:provinsi="provinsi"
            v-can:hide.distributor
          />
           <filter-kabupaten-kota
            class="m-2 mt-2"
            v-model:value="kabupaten"
            v-model:region="region"
            v-model:area="area"
            v-model:provinsi="provinsi"
            v-can:hide.distributor
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <filter-distributor
             v-if="!isDistributor && !isDistributor_khusus"
            class="m-2 mt-2"
            v-model:value="distributor"
            v-model:distributor="distributor"
            v-can:hide.distributor
          ></filter-distributor>
          <a-select
            :options="[
              { value: null, label: 'Semua' },
              { value: 2, label: 'Approve ASM' },
              { value: 1, label: 'Approve TSO' },
              { value: 0, label: 'New' },
              { value: 6, label: 'Reject' },
            ]"
            class="m-2"
            style="width: 300px;"
            placeholder="Semua Status"
            v-model:value="status"
          ></a-select>
          <filter-segment
            class="m-2 mt-2"
            :mode="null"
            style="width: 300px;"
            placeholder="Pilih Tipe Customer"
            v-model:value="segment"
          />
          <filter-cluster
            class="m-2 mt-2"
            :mode="null"
            show-search
            style="width: 300px;"
            placeholder="Pilih Tipe Cluster"
            v-model:value="cluster"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a-select
            :options="[
              { value: 1, label: 'Aktif' },
              { value: 0, label: 'Tidak Aktif' },
            ]"
            :mode="null"
            allow-clear
            class="m-2 mt-2"
            style="width: 300px;"
            placeholder="Pilih Status Toko"
            v-model:value="active"
          >
          </a-select>
           <a-input-search
            v-model:value="q"
            placeholder="Cari ..."
            style="width: 300px"
            class="m-2 mt-2"
          />
          <a-button class="m-2 mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
            <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
            <span v-else>Memuat Data ...</span>
          </a-button>
        </div>
      </div>
      <div class="row justify-content-end mt-4">
        <div class="col-lg-6 col-md-12"></div>
        <div class="col-lg-6 col-md-12 text-right">
          <a-button
            title="Menyetujui Toko"
            type="primary"
            @click="showConfirm"
            v-has-access="['customers.approve']"
          >
            <i class="fa fa-check" aria-hidden="true"></i>
          </a-button>
          <a-modal
            title="Konfirmasi Penyetujuan Toko"
            v-model:visible="modal5Visible"
            @ok="confirm"
            :confirm-loading="loading"
          >
            <p v-if="selectedRowKeys.length === 0">Checklist toko yang akan di setuju</p>
            <p v-else>Anda yakin ingin menyetujui toko?</p>
          </a-modal>
  
          <a-button
            class="ml-2 mr-2"
            title="Rejected / Hapus"
            type="danger"
            @click="showDelete"
            v-has-access="['customers.destroy']"
          >
            <i class="fa fa-ban" aria-hidden="true"></i>
            <a-modal
              title="Informasi"
              v-model:visible="modal2Visible"
              @ok="hapus"
              :confirm-loading="loading"
            >
              <p v-if="status_approval === 0">Checklist toko yang akan di hapus</p>
              <p v-else>Anda yakin ingin menolak/hapus toko terpilih?</p>
            </a-modal>
          </a-button>
  
          <a-button
            title="Tambah Toko"
            type="primary"
            v-has-access="['customers.store']"
            @click="
              () => {
                formState = {}
                formState.status_approval = 0
                formState.type = 2
                modal3Visible = true
                formState.title_state = 'Tambah Toko'
                formState.state = 'new'
                formState.readOnly = false
              }
            "
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </a-button>
  
          <a-button
            class="ml-2"
            title="download excel"
            type="primary"
            @click="fetchXlsx"
            :loading="isDownloading"
          >
            <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
            <span v-else>Downloading ...</span>
          </a-button>
        </div>
      </div>
      <div class="table-responsive text-nowrap mt-2">
        <md-table
          :columns="columns"
          :data-source="data"
          size="small"
          row-key="id"
          :pagination="{
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
            showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
            total: meta.totalCount,
            pageSize: meta.perPage,
            current: meta.currentPage,
          }"
          :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="handleTableChange"
          :loading="isFetching"
        >
          <template #no="{ index }">
            <span>
              {{ startRow + index }}
            </span>
          </template>
          <template #active="{ text }">
            <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
            <a-tag v-else color="grey">Tidak Aktif</a-tag>
          </template>
          <template #status="{ text }">
            <a-tag v-if="text" color="#108ee9">Disetujui oleh ASM</a-tag>
            <a-tag v-else color="grey">Belum disetujui oleh ASM</a-tag>
          </template>
          <template #action="{record}">
            <span>
              <a-tooltip title="Lihat Toko">
                <a
                  href="javascript: void(0);"
                  class="btn btn-sm btn-light"
                  @click="lihatPengguna(record.id)"
                  v-has-access="['customers.show']"
                >
                  <small>
                    <i class="fe fe-eye" />
                  </small>
                </a>
              </a-tooltip>
              <a-tooltip title="Ubah Toko">
                <a
                  href="javascript: void(0);"
                  class="btn btn-sm btn-light"
                  v-has-access="['customers.update']"
                  @click="editPengguna(record.id)"
                >
                  <small>
                    <i class="fe fe-edit" />
                  </small>
                </a>
              </a-tooltip>
            </span>
          </template>
        </md-table>
      </div>
    </div>
    <!-- <pre>{{ state }}</pre> -->
    <a-modal
      v-model:visible="modal3Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modal3Visible = false"
      v-if="modal3Visible"
    >
      <pre v-if="!true">{{ formState }}</pre>
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >{{ formState.reactive ? 're-aktivasi' : 'Simpan' }}</a-button
        >
      </template>
      <m-modal ref="modalRef"></m-modal>
    </a-modal>
  </template>
  
  <script>
  import { ref, watch, onMounted, reactive, provide, toRefs, computed, h } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import apiClient from '@/services/axios'
  import MModal from './modal'
  import { Modal, message, Alert } from 'ant-design-vue'
  import FilterRegional from '@/components/filter/FilterRegional'
  import FilterProvince from '@/components/filter/FilterProvince'
  import FilterArea from '@/components/filter/FilterArea'
  import FilterDistributor from '@/components/filter/FilterDistributor'
  import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
  import FilterSegment from '@/components/filter/FilterSegment'
  import FilterCluster from '@/components/filter/FilterCluster'
  import qs from 'qs'
  import moment from 'moment'
  import { serialize } from 'object-to-formdata'
  import useUserInfo from '@/composables/useUserInfo'
  import { columns } from './columns'
  import _ from 'lodash'
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows)
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows)
    },
  }
  export default {
    name: 'MDataToko',
    components: {
      MModal,
      // Modall,
      FilterRegional,
      FilterProvince,
      FilterArea,
      FilterDistributor,
      // FilterWilayahSemua,
      FilterKabupatenKota,
      FilterSegment,
      FilterCluster,
    },
    setup() {
      const route = useRoute()
      // const modalRef = ref('')
  
      const modal1Visible = ref(false)
      const modal2Visible = ref(false)
      const modal3Visible = ref(false)
      const modal4Visible = ref(false)
      const modal5Visible = ref(false)
      const setModal1Visible = visible => {
        modal1Visible.value = visible
      }
  
      const loading = ref(false)
  
      const perPage = ref(10)
      const pageCount = ref(0)
      const totalCount = ref(0)
      const meta = ref({})
      const page = ref(1)
  
      const region = ref([])
      const provinsi = ref([])
      const kabupaten = ref([])
      const segment = ref([])
      const distributor = ref([])
      const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
      if (isDistributor.value || isDistributor_khusus.value) {
        distributor.value.push(vendor_id.value)
      }
      const area = ref([])
      const modalRef = ref('')
  
      const dataRegional = ref([])
      const dataProvince = ref([])
      const dataArea = ref([])
      const status = ref(null)
      const cluster = ref(null)
  
      const data = ref([])
      const q = ref('')
      const searchInput = ref(null)
      const active = ref(null)
  
      const isPosting = ref(false)
      const formState = ref({
        status_approval: 0,
        foto_ktp: null,
        status_approval: 0,
        wilayah_id: null,
        type: 1,
        status: null,
        status_text: null,
        reactive: false,
        customerCodes: [],
        codes: [], // kode referensi/multi code
        users:[],
        brands: [],
      })
  
      const vendors = ref({
        vendor_id: [],
      })
  
  
      const errorMessage = ref(null)
  
      const startRow = ref(1)
      const handleTableChange = pag => {
        page.value = pag.current
        perPage.value = pag.pageSize
        fetchData()
        startRow.value = (page.value - 1) * perPage.value + 1
      }
  
      const params = ref({})
      const fetchData = () => {
        const _params = {
          page: page.value,
          'per-page': perPage.value,
          region: region.value,
          area: area.value,
          provinsi: provinsi.value,
          kabupaten: kabupaten.value,
          // produk: products.value,
          // brand: brands.value,
          distributor: distributor.value,
          type: segment.value,
          status: status.value,
          q: q.value,
          active: active.value,
          cluster: cluster.value,
        }
        params.value = Object.assign({}, _params)
        state.isFetching = true
        apiClient
          .get('/api/customers-v2', {
            params: _params,
          })
          .then(response => {
            if (response === undefined) {
              errorMessage.value = `Kode error 500, No response from server`
              return
            }
            const { items, _meta } = response.data
            data.value = items
            meta.value = _meta || { pageCount: 0, totalCount: 0 }
            pageCount.value = meta.value.pageCount
            totalCount.value = meta.value.totalCount
          })
          .catch(async error => {
            errorMessage.value = null
            if (error.response) {
              const { status, statusText } = error.response
              const message = error.response.data.message.substring(0, 150)
              errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
            }
          })
          .finally(() => {
            state.isFetching = false
          })
      }
      const lihatPengguna = id => {
        apiClient
          .get(`/api/customers-v2/${id}`)
          .then(response => {
            const data = response.data
            formState.value = data
            modal3Visible.value = true
            formState.value.title_state = 'Lihat Toko'
            formState.value.readOnly = true
            formState.value.foto = formState.value.foto_toko
            formState.value.vendorRaw = formState.value.vendors.map(v => ({
            ...v,
              name: `${v.value} - ${v.label}`,
          }))
          formState.value.vendors = formState.value.vendors.map(v => ({key: v.id, value: v.id, label: `${v.code} - ${v.name}`}))
          })
          .catch(error => console.error({ showFailed: error }))
          .catch(error => console.error({ showCodesFailed: error }))
      }

      const editPengguna = id => {
        apiClient
          .get(`/api/customers-v2/${id}`)
          .then(response => {
            const data = response.data
            formState.value = data
            modal3Visible.value = true
            formState.value.foto = formState.value.foto_toko
            formState.value.title_state = 'Edit Toko'
            formState.value.state = 'edit'
            formState.value.readOnly = false
            formState.value.vendorRaw = formState.value.vendors.map(v => ({
              ...v,
              name: `${v.value} - ${v.label}`,
            }))
            formState.value.vendors = formState.value.vendors.map(v => ({key: v.id, value: v.id, label: `${v.code} - ${v.name}`}))
            formState.value.reactive =
                formState.value.status === 'Reject' || formState.value.is_deleted === true
            })
          .catch(error => console.error({ failure: error }))
  
          .catch(error => console.error(error))
      }
  
      const search = () => {
        fetchData()
      }
  
      const fetchXlsx = () => {
        state.isDownloading = true
        apiClient
          .get('/api/customers-v2', {
            params: {
              ...params.value,
              _export: 'xls',
              _columns: columns
                .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
                .map(({ dataIndex: key, title }) => ({ key, title })),
            },
            paramsSerializer: function(params) {
              return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
            },
            responseType: 'blob',
            headers: {},
          })
          .then(response => {
            if (response === undefined) {
              errorMessage.value = `Kode error 500, No response from server`
              return
            }
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `master-toko_${moment().format('DDMMYY_HHmm')}.xlsx`)
            document.body.appendChild(link)
            link.click()
          })
          .catch(async error => {
            errorMessage.value = null
            if (error.response) {
              const { status, statusText } = error.response
              const message = JSON.parse(await error.response.data.text()).message
              errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
            }
          })
          .finally(() => {
            state.isDownloading = false
          })
      }
      const submit = async () => {
        try {
          await modalRef.value.formRef.validate()
        } catch (error) {
          console.error('submit', error)
          return
        }
  
        const formatMoment = (value) => {
          return value instanceof moment ? value.format('YYYY-MM-DD') : value
        }
  
        if (formState.value.state === 'new') {
          Modal.confirm({
            title: 'Konfirmasi Ajukan Toko',
            content: 'Apakah anda ingin melanjutkan Ajukan Toko ?',
  
            onOk() {
              isPosting.value = true
              const form_data = serialize(
                {
                  ..._.omit(formState.value, [
                    'reactive',
                    'state',
                    'title_state',
                    'readOnly',
                    'codes',
                    'users',
                    'vendors',
                    'brands',
                  ]),
                  vendors: formState.value.vendors.map(v => ({ vendor_id: v.value})),
                  codes: formState.value.codes.map(value => ({
                    ...value,
                    begin_date: formatMoment(value.begin_date),
                    end_date: formatMoment(value.end_date),
                  })),
                  brands: formState.value.brands.map(value => ({
                    ...value,
                    vendor_id: value.vendor_id ?? 0,
                  })),
                  users: formState.value.users.map(value => ({
                    ...value,
                    user_id: value.user_id ?? 0,
                    begin_date: undefined,
                    end_date: undefined,
                  })),
                },
               { indices: true },
              )
              apiClient
                .post('/api/customers-v2', form_data)
                .then(({ data }) => {
                  modal3Visible.value = false
                  isPosting.value = false
                  fetchData()
                  message.success('Berhasil ditambah')
                })
                .catch(({ response: { data, status } }) => {
                  if (status === 422) {
                    data[0].message
                  } else data.message = 'Gagal menyimpan data! cek data kembali'
                  Modal.error({
                    title: 'Gagal Simpan',
                    content: data[0].message,
                    onOk() {
                      console.log('ok')
                    },
                  })
                })
                .finally(() => (isPosting.value = false))
            },
            onCancel() {},
          })
        } else if (formState.value.state === 'edit') {
          if (
            formState.value.reactive &&
            (formState.value.audit_trail_note === undefined ||
              formState.value.audit_trail_note === '' ||
              formState.value.audit_trail_note === null)
          ) {
            const respon = new Promise(resolve => {
              Modal.warn({
                title: 'Alasan belum di isi!',
                onOk() {
                  resolve(true)
                },
              })
            })
            await respon
            return
          }
  
          Modal.confirm({
            title: 'Konfirmasi Edit Toko',
            content: 'Apakah anda ingin melanjutkan Edit Toko ?',
            onOk() {
              isPosting.value = true
  
              const form_data = serialize(
                {
                  ..._.omit(formState.value, [
                    'reactive',
                    'state',
                    'title_state',
                    'readOnly',
                    'vendorRaw',
                    'codes',
                    'users',
                    'vendors',
                    'brands',
                  ]),
                  vendors: formState.value.vendors.map(v => ({ vendor_id: v.value })),
                  codes: formState.value.codes.map(value => ({
                    ...value,
                    begin_date: formatMoment(value.begin_date),
                    end_date: formatMoment(value.end_date),
                  })),
                  brands: formState.value.brands.map(value => ({
                    ...value,
                    vendor_id: value.vendor_id ?? 0,
                  })),
                  users: formState.value.users.map(value => ({
                    ...value,
                    user_id: value.user_id ?? 0,
                    begin_date: undefined,
                    end_date: undefined,
                  })),
                },
                { indices: true },
              )
              apiClient
                .post('/api/customers-v2/' + formState.value.id + '?_method=PUT', form_data)
                .then(({ data }) => {
                  modal3Visible.value = false
                  isPosting.value = false
                  fetchData()
                  message.success('Berhasil disimpan')
                })
                .catch(({ response: { data, status } }) => {
                  if (status === 422) {
                    data[0].message
                  } else data.message = 'Gagal menyimpan data! cek data kembali'
                  Modal.error({
                    title: 'Gagal Simpan',
                    content: data[0].message,
                    onOk() {
                      console.log('ok')
                    },
                  })
                })
                .finally(() => (isPosting.value = false))
            },
            onCancel() {},
          })
        }
      }
  
      const onSelectChange = (selectedRowKeys, selectedRows, dataIndex) => {
        state.selectedRowKeys = selectedRowKeys
        state.selectedRows = selectedRows
      }
  
      const visible = ref(false)
  
      const state = reactive({
        selectedRowKeys: [],
        loading: false,
        isFetching: false,
        isDownloading: false,
      })
  
  
      const showDelete = visible => {
        if (visible) {
          if (state.selectedRowKeys.length === 0) {
            Modal.warning({
              content: 'Checklist toko yang akan di reject/nonaktifkan!',
            })
            return
          }
        }
        modal2Visible.value = visible
      }
  
      const hapus = () => {
        // approva by single post
        apiClient
          .post('/api/customers/reject', {
            ids: state.selectedRowKeys,
            // ids: [103, 104],
          })
          .then(res => {
            state.loading = true
            modal2Visible.value = false
            state.selectedRowKeys = []
            fetchData()
            state.loading = false
  
            let details = null
            if (res.data && res.data.details) {
              details = res.data.details.map(er => {
                if (!er.errors) return
  
                return h(
                  Alert,
                  { type: 'error', message: `Kode Toko (${er.code}) : ${er.errors[0]}` },
                  '',
                )
              })
            }
  
            Modal.info({
              title: 'Reject',
              content: h('span', {}, [`${res.data.message}`, h(`span`, {}, details)]),
              onOk() {
                console.log('ok')
              },
            })
          })
          .catch(response => {
            const { data, status } = response
  
            if (status === 401) {
              data.message = 'Anda tidak memiliki hak untuk reject data ini!'
            }
            Modal.error({
              title: 'Approval',
              content: response.message,
              onOk() {
                console.log('ok')
              },
            })
          })
      }
  
  
      const showConfirm = visible => {
        if (visible) {
          if (state.selectedRowKeys.length === 0) {
            Modal.warning({
              content: 'Checklist toko yang akan di konfirmasi!',
            })
            return
          }
        }
        modal5Visible.value = visible
      }
  
      const confirm = () => {
        // approva by single post
        apiClient
          .post('/api/customers/approve', {
            ids: state.selectedRowKeys,
            // ids: [103, 104],
          })
          .then(res => {
            state.loading = true
            modal5Visible.value = false
            state.selectedRowKeys = []
            fetchData()
            state.loading = false
  
            let details = null
            if (res.data && res.data.details) {
              details = res.data.details.map(er => {
                if (!er.errors) return
  
                return h(
                  Alert,
                  { type: 'error', message: `Kode Toko (${er.code}) : ${er.errors[0]}` },
                  '',
                )
              })
            }
  
            Modal.info({
              title: 'Approval',
              content: h('span', {}, [`${res.data.message}`, h(`span`, {}, details)]),
              onOk() {
                console.log('ok')
              },
            })
          })
          .catch(response => {
            const { data, status } = response
  
            if (status === 401) {
              data.message = 'Anda tidak memiliki hak untuk approve data ini!'
            }
            Modal.error({
              title: 'Approval',
              content: response.message,
              onOk() {
                console.log('ok')
              },
            })
          })
      }
  
      provide('formState', formState)
  
      onMounted(() => {
        const { distributor: disti_id } = route.query
        if (disti_id) {
          if (!distributor.value.includes(Number(disti_id))) distributor.value.push(Number(disti_id))
        }
        fetchData()
      })
  
      return {
        perPage,
        pageCount,
        totalCount,
        meta,
        page,
        rowSelection,
        columns,
        data,
        q,
        searchInput,
        startRow,
        region,
        provinsi,
        kabupaten,
        distributor,
        area,
        segment,
        state,
        ...toRefs(state),
        fetchData,
        search,
        modal2Visible,
        modal3Visible,
        modal4Visible,
        modal5Visible,
        setModal1Visible,
        fetchXlsx,
        isPosting,
        formState,
        onSelectChange,
        visible,
        editPengguna,
        lihatPengguna,
        handleTableChange,
        hapus,
        submit,
        dataRegional,
        dataProvince,
        dataArea,
        loading,
        confirm,
        modalRef,
        active,
        errorMessage,
        status,
        vendors,
        cluster,
        showConfirm,
        showDelete,
        isDistributor,
        isDistributor_khusus,
      }
    },
  }
  </script>
  <style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
  
  .ant-table-striped :deep(.table-striped) {
    background-color: #fafafa;
  }
  </style>
  