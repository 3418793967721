export const columns = [
    //  {
    //   title: 'Tanggal Pendaftaran',
    //   dataIndex: 'created_at',
    // },
    {
      title: 'Tanggal Registrasi',
      dataIndex: 'registration_at',
    },
  
    {
      title: 'ID Toko',
      dataIndex: 'code',
    },
    {
      title: 'Nama Toko',
      dataIndex: 'name',
    },
    {
      title: 'Kode Distributor',
      dataIndex: 'distributor_code',
    },
    {
      title: 'Nama Distributor',
      dataIndex: 'distributor_name',
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
    },
    {
      title: 'No Telepon',
      dataIndex: 'phone',
    },
    {
      title: 'Nama Pemilik Toko',
      dataIndex: 'owner',
    },
    {
      title: 'Kelurahan / Desa Toko',
      dataIndex: 'desa',
    },
  
    {
      title: 'Kecamatan Toko',
      dataIndex: 'kecamatan',
    },
    {
      title: 'Kabupaten Toko',
      dataIndex: 'kabupaten',
    },
    {
      title: 'Area Toko',
      dataIndex: 'area',
    },
    {
      title: 'Provinsi Toko',
      dataIndex: 'provinsi',
    },
    {
      title: 'Region Toko',
      dataIndex: 'region',
    },
    {
      title: 'Tipe Customer',
      dataIndex: 'type_name',
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster_name',
    },
    {
      title: 'SSM',
      dataIndex: 'user_ssm',
    },
    {
      title: 'ASM',
      dataIndex: 'user_sm',
    },
    {
      title: 'TSO',
      dataIndex: 'user_am',
    },
    {
      title: 'Status Approval',
      dataIndex: 'status',
    },
    {
      title: 'Status Toko',
      // slots: { customRender: 'active' },
      dataIndex: 'status_text',
    },
    {
      title: 'Tanggal Nonaktif',
      dataIndex: 'nonaktif_at',
    },
    {
      title: 'Action',
      slots: { customRender: 'action' },
    },
  ]
  
  